/**
 * key String 当前的key
 * key Object
 * {
 *     targetKey: 'fn' // arr item 中的事件值
 *     curKey: 'export',  // 点击 按钮的 事件值
 * }
*/

export default (arr, curKey, status) => {
    if (!Array.isArray(arr) || typeof status === 'undefined') {
        throw new Error('请传入正确参数');
    };

    const getTargetKey = () => {
        const str = Object.prototype.toString;
        const type = '[object Object]';

        if (str.call(curKey) === type) {
            let targetKey = Object.prototype.hasOwnProperty.call(curKey, 'targetKey')
                            ? curKey['targetKey']
                            : Object.keys(curKey)[0];

            curKey = curKey['curKey'];
            return targetKey;
        } else {
            return 'fn';
        }
    };

    let targetKey = getTargetKey(curKey);
    console.log(arr, targetKey, curKey, 'curKey==')
    arr.forEach(item => {
        if (item[targetKey] === curKey) {
            item.loading = status;
        }
    })
}
