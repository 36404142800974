let browserInfo = (function (win){
    if (!win) return '';
    
    const W = '宽',
        H = '高',
        Z = '放大倍数';

    const plugin = {
        // 检查放大倍数
        detectZoom: function (){
            var ratio = 0,
                screen = win.screen,
                ua = navigator.userAgent.toLowerCase();
            
            if (win.devicePixelRatio !== undefined) {
                ratio = win.devicePixelRatio;
            }
            else if (~ua.indexOf('msie')) {
                if (screen.deviceXDPI && screen.logicalXDPI) {
                ratio = screen.deviceXDPI / screen.logicalXDPI;
                }
            }
            else if (win.outerWidth !== undefined && win.innerWidth !== undefined) {
                ratio = win.outerWidth / win.innerWidth;
            }
            
            if (ratio){
                ratio = Math.round(ratio * 100);
            }
            
            return ratio + '%';
        }
    };

    function browserInfo () {
        return () => {
            let viewportWidth = win.innerWidth;
            let viewportHeight = win.innerHeight;
            let zoomRatio = plugin.detectZoom();

            return [
                W + viewportWidth,
                H + viewportHeight,
                Z  + zoomRatio
            ].join(':');
        }
    };

    return browserInfo

}(window));


export default browserInfo;