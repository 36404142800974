/**
 * 前端脱敏处理
 * 
 */
class Desensitization {
    phone(list, phoneKey) {
        let phone = phoneKey || 'phone'
        return list.map((item) => {
            if (item[phone]) {
                item[phone] = item[phone].slice(0,3) + '*****' + item[phone].slice(item[phone].length - 3)
            }
            return item;
        })
    }
    idCard(list) {
        return list.map((item) => {
            if (item.idCard) {
                item['_idCard'] = JSON.parse(JSON.stringify(item.idCard))
                item.idCard = item.idCard.slice(0,3) + '***********' + item.idCard.slice(item.idCard.length - 4)
            }
            return item;
        })
    }
}

export {
    Desensitization
}