/**
 * @Description: 表单是否完成填写必填项返回未填写数据
 * @DoWhat: 锚点展示未填写必填项
 * @UseScenarios:
 * @Attention:
 * @Author: 武东超
 * @Date: 2023-07-19 14:16:02
 * @param {*} anchorList 锚点数据
 * @param {*} formRules 验证规则
 * @param {*} ruleForm 验证数据源
 */
function formRequiredFields (anchorList, formRules, ruleForm) {
    let str = Object.prototype.toString,
        type = '[object Array]';

    Object.keys(formRules).forEach((key) => {
        let rulesItem = formRules[key];

        if (rulesItem.length > 0) {
            let valType = true,
                unfinishedNum = 0;
            // 查看是否有值
            Object.keys(ruleForm).forEach((subKey) => {
                rulesItem.forEach((subItem) => {
                    if (subKey === subItem ) {
                        if(str.call(ruleForm[subKey]) == type && ruleForm[subKey].length == 0) {
                            valType = false;
                            unfinishedNum ++;
                        } else if (!ruleForm[subKey]) {
                            valType = false;
                            unfinishedNum ++;
                        }
                    }
                })
            })

            // 拼装提示信息
            anchorList.forEach((subItem) => {
                if (subItem.rules === key) {
                    if (valType) {
                        subItem.text = ''
                    } else {
                        subItem.text = splicingCues(unfinishedNum)
                    }

                    subItem.finish = valType;
                }
            })
        }
    })

    return anchorList;
}

function splicingCues (unfinishedNum) {
    return `${unfinishedNum}必填项未填`
}

export default formRequiredFields;
