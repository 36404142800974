import axios from 'axios';
import qs from 'qs';
import router from '@/router/index.js';
import {
    getToken
} from '@/libs/auth';
import responseMessage from "@/assets/data/responseMessage";
import getProjectMark from '@/libs/getProjectMark.js'
import { remove7DayToken } from '@/libs/rememberPasswords.js';
// 设置全局超时时间
axios.defaults.timeout = 1000 * 60 * 2;
// 请求拦截器
axios.interceptors.request.use(config => {
    let token = getToken();

    if (token) {
        config.headers['Authorization'] = token;
    }
    if (config.method === "post" && !config.data) {
        config.data = {}
    }
    return config;
}, err => {
    return Promise.reject(err);
});
// 响应拦截器
axios.interceptors.response.use(function (response) {
    const res = response.data;
    if (!res) return response
    if (res.code === '5015' || res.code === '5014') {
        remove7DayToken(getProjectMark())
        setTimeout(() => {
            router.push({name:"login"})
        },200)
    }
    return handleResMessage(response);
}, function (error) {
    return Promise.reject(error);
});

function handleResMessage (res) {
    responseMessage.messageList.forEach((item) => {
        if (item.code.includes(res.data.code)) {
            res.data.msg = item.message
        }
    })
    return res
}

class HTTP {
    axiosPost (options) {
        axios({
            url: options.url,
            method: 'post',
            headers: {
                'Content-Type': options.header ? options.header : 'application/x-www-form-urlencoded',
                'AuthFlag':options.authFlag ? options.authFlag : 'N',
                "ShareFlag":options.ShareFlag?options.ShareFlag:"N"
            },
            responseType: options.responseType ? options.responseType : 'json',
            data: qs.parse(options.data)
        }).then((res) => {
            options.responseType == 'blob' ? options.success(res) : options.success(res.data)
        }).catch((err) => {
            options.error(err);
        })
    };

    axiosPostArray (options) {
        axios({
            url: options.url,
            method: 'post',
            headers: {
                'Content-Type': options.header ? options.header : 'application/x-www-form-urlencoded',
                'AuthFlag':options.authFlag ? options.authFlag : 'N'
            },
            responseType: options.responseType ? options.responseType : 'json',
            data: options.data
        }).then((res) => {
            options.success(res.data);
        }).catch((err) => {
            options.error(err);
        })
    };

    axiosGet (options) {
        axios({
            url: options.url,
            method: 'get',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: qs.stringify(options.data)
        }).then((res) => {
            options.success(res.data);
        }).catch((err) => {
            options.error(err);
        });
    }

    axiosFile (options) {
        axios({
            url: options.url,
            method: 'post',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: options.data
        }).then((res) => {
            options.success(res.data);
        }).catch((err) => {
            options.error(err);
        });
    }

    get (url, data) {
        return new Promise((resolve, reject) => {
            this.axiosGet({
                url: url,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }

    fet (url, data, options) {
        return new Promise((resolve, reject) => {
            let fn = options?.isArrParams ? this.axiosPostArray : this.axiosPost
            fn({
                url: url,
                data: data,
                header: 'application/json',
                responseType: options && options.responseType ? options.responseType : 'json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }
}

export { HTTP }
